.form-login {
    display: inline-block;
    max-width: 405px;
    width: 100%;
    border-radius: 16px;
    margin: auto;
    border-width: 1px;
    border-style: solid;
    padding: 30px;
    .btn {
        width: 100%;
        height: 48px;
    }
}
.box-form-login {
    text-align: center;
    position: relative;
    &::before {
        content: "";
        height: 312px;
        width: 329px;
        position: absolute;
        bottom: -50px;
        right: 0px;
        background: url(../imgs/page/login/security.svg) no-repeat bottom right;
    }
}
.box-line {
    position: relative;
    margin: 30px auto;
    max-width: 375px;
    &::before {
        content: "";
        width: 90%;
        height: 1px;
        background-color: $color-gray-800;
        position: absolute;
        top: 10px;
        left: 0px;
        right: 0px;
        z-index: 1;
        margin: auto;
    }
    span {
        display: inline-block;
        position: relative;
        top: -4px;
        z-index: 2;
        padding: 5px 10px;
    }
}
.box-login-gmail {
    border-radius: 16px;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    max-width: 405px;
    width: 100%;
    padding: 8px;
}
.btn-login-gmail {
    background: url(../imgs/page/login/gmail.svg) no-repeat left center;
    padding: 0px 0px 0px 30px;
}
.viewpass {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-right: 30px;
    background-image: url(../imgs/page/login/view.svg);
    background-position: center;
    background-repeat: no-repeat;
    height: 65px;
    width: 50px;
    cursor: pointer;
}