.box-map {
    overflow: hidden;
    width: 100%;
    border-radius: 16px;
    display: inline-block;
}

iframe.google-map {
    width: 100%;
    height: 450px;
    border-radius: 16px;
}