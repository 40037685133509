.mobile-header-wrapper-style {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 360px;
    min-height: 100vh;
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
    z-index: 9999999;

    &.sidebar-visible {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .mobile-header-wrapper-inner {
        padding: 0px 0px 30px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .mobile-logo {
            position: relative;
            border-bottom: 1px solid $color-border-1;
            padding-bottom: 15px;
        }

        .mobile-header-top {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 0;
            background-color: #ffffff;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            align-self: center;
            border-bottom: 0;
            margin-bottom: 15px;

            .mobile-header-logo {
                a {
                    display: block;

                    img {
                        width: 100px;
                    }
                }
            }
        }

        .mobile-header-content-area {
            padding: 30px 30px 30px;

            .mobile-menu-wrap {
                nav {
                    .mobile-menu {
                        max-width: 300px;

                        li {
                            display: block;
                            position: relative;
                            padding: 13px 0;

                            &:last-child {
                                border-bottom: none;
                            }

                            &.has-children {
                                .menu-expand {
                                    right: 0;
                                    position: absolute;
                                    cursor: pointer;
                                    z-index: 9;
                                    text-align: center;
                                    font-size: 12px;
                                    display: block;
                                    width: 30px;
                                    height: 30px;
                                    line-height: 38px;
                                    top: 5px;
                                    color: $color-gray-50;

                                    i {
                                        font-size: 18px;
                                        font-weight: 300;
                                        opacity: 0.5;
                                    }
                                }

                                &.active {
                                    &>.menu-expand {
                                        background: $color-gray-800;

                                        i {
                                            &::before {
                                                content: "\f113";
                                            }
                                        }
                                    }
                                }
                            }

                            a {
                                font-size: $font-md;
                                font-family: $font-text;
                                line-height: 1;
                                text-transform: capitalize;
                                font-weight: 500;
                                position: relative;
                                display: inline-block;
                                color: $color-gray-50;
                                transition-duration: 0.2s;

                                i {
                                    margin-right: 5px;
                                }
                            }

                            ul {
                                padding: 10px 0 0 10px;

                                li {
                                    padding: 10px 0;
                                    border-bottom: none;

                                    &.has-children {
                                        .menu-expand {
                                            top: 0px;
                                        }
                                    }

                                    &:hover {
                                        a {
                                            padding-left: 13px;
                                            transition-duration: 0.2s;
                                        }
                                    }

                                    a {
                                        font-size: $font-sm;
                                        display: block;
                                        font-weight: 500;
                                        color: $color-gray-50;
                                        padding-left: 10px;
                                        position: relative;
                                        transition-duration: 0.2s;
                                    }

                                    ul {
                                        margin-top: 0;
                                    }

                                    &.has-children.active {
                                        padding-bottom: 0;
                                    }

                                    &.hr {
                                        padding: 0 0 0 10px;

                                        span {
                                            background-color: $color-gray-100;
                                            height: 1px;
                                            width: 100%;
                                            display: block;
                                            margin: 5px 0;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                &>a {
                                    color: $color-gray-500;
                                    padding-left: 3px;
                                    transition-duration: 0.2s;
                                }
                            }
                        }
                    }
                }
            }

            .categories-dropdown-wrap ul li a {
                padding: 5px 15px;
            }

            .mobile-header-info-wrap {
                padding: 20px;
                border-radius: 5px;
                border: 1px solid $color-gray-900;
                margin: 17px 0 30px 0;

                .single-mobile-header-info {
                    position: relative;
                    margin-bottom: 13px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a {
                        font-size: $font-sm;
                        display: block;
                        font-weight: 500;
                        color: $color-gray-900;

                        &:hover {
                            color: $color-gray-900;
                        }

                        i {
                            font-size: $font-sm;
                            color: $color-gray-900;
                            margin-right: 8px;
                        }
                    }

                    .lang-curr-dropdown {
                        margin-top: 5px;
                        display: none;
                        background-color: transparent;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        padding: 10px 0 0 0;
                        width: 100%;
                        z-index: 11;

                        ul {
                            li {
                                padding-bottom: 10px;

                                &:last-child {
                                    padding-bottom: 0px;
                                }

                                a {
                                    font-size: $font-md;
                                    color: rgba(255, 255, 255, 0.7);
                                    font-weight: 400;

                                    &:hover {
                                        color: $color-gray-900;
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        &>a {
                            color: $color-gray-900;
                        }
                    }
                }
            }

            .mobile-header-border {
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            }

            .site-copyright {
                font-size: $font-xs;
                color: $color-gray-50;
                a {
                    color: $color-gray-50;
                }
            }
        }
    }
}

.burger-icon {
    position: absolute;
    width: 24px;
    height: 20px;
    cursor: pointer;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    top: 15px;
    right: 0px;
    z-index: 99999999;

    &>span {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;

        &.burger-icon-top {
            top: 2px;
        }

        &.burger-icon-bottom {
            bottom: 2px;
        }

        &.burger-icon-mid {
            top: 9px;
        }
    }

    &.burger-close {
        position: fixed;
        top: 32px;
        right: 30px;

        &>span {
            &.burger-icon-top {
                display: none;
                opacity: 0;
            }

            &.burger-icon-mid {
                top: 8px;
                transform: rotate(45deg);
            }

            &.burger-icon-bottom {
                bottom: 10px;
                transform: rotate(-45deg);
            }
        }
    }
}

.burger-icon>span::before,
.burger-icon>span::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #333;
}

.burger-icon.burger-icon-white>span::before,
.burger-icon.burger-icon-white>span::after {
    background-color: $color-gray-500;
}

.mobile-search {
    form {
        background-color: $background-white;
        border-radius: 8px;
        height: 44px;
        padding: 0px 15px 0px 44px;
        position: relative;

        input {
            border: 0;
            background-color: transparent;
            width: 100%;
            padding: 12px 10px;
        }

        i {
            position: absolute;
            left: 18px;
            top: 14px;
            font-size: 18px;
            color: $color-gray-800;
        }
    }
}

.mobile-social-icon {
    h6 {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 30px;
    }

    a {
        text-align: center;
        font-size: 14px;
        margin-right: 5px;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        height: 30px;
        width: 30px;
        display: inline-flex;
        background: #efeaff;
        border-radius: 30px;
        line-height: 1;
        align-content: center;
        justify-content: center;

        img {
            max-width: 18px;

            &:hover {
                opacity: 0.8;
            }
        }

        &:hover {
            transform: translateY(-2px);
            transition-duration: 0.5s;
            margin-top: -2px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.mobile-account {
    margin: 20px 0 25px 0;
    padding: 30px 0 20px 0;
    border-top: thin solid $color-gray-100;
    border-bottom: thin solid $color-gray-100;
    ul.mobile-menu {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
    h6 {
        font-weight: 600;
        font-size: 18px;
    }

    ul {
        li {
            padding: 8px 0;

            a {
                font-size: 14px;
                line-height: 1;
                text-transform: capitalize;
                font-weight: 400;
                position: relative;
                display: inline-block;
                color: $color-gray-50;
                transition-duration: 0.2s;
            }

            &:hover a {
                padding-left: 3px;
                transition-duration: 0.2s;
                color: $color-gray-500;
            }
        }
    }
}

.topbar {
    padding: 10px 70px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid $color-border-1;
    z-index: 9999999;
    position: relative;
    background: $background-white;

    .container-topbar {
        display: flex;

        .menu-topbar-left,
        .menu-topbar-right,
        .info-topbar {
            width: 33.3%;
        }

        .menu-topbar-right {
            text-align: right;
        }
    }
}

.top-gray-1000 {
    background-color: $color-gray-900;

    .nav-small li a {
        color: $color-white;
    }

    .color-brand-3 {
        color: $color-white !important;
    }

    .dropdown-right {
        background-image: url(../imgs/page/homepage4/arrow-down.svg);
    }
}

.dropdown-right {
    background-image: url(../imgs/template/arrow-down.svg);
    background-position: right center;
    background-repeat: no-repeat;
    padding: 0px 15px 0px 0px;
    display: inline-block;
    margin-left: 10px;
}

.nav-small {
    li {
        position: relative;
        display: inline-block;

        a {
            display: block;
            padding: 0px 30px 0px 0px;
            color: $color-brand-3;

            &:hover {
                color: $color-brand-2;
            }

            &::before {
                content: "";
                position: absolute;
                top: 5px;
                right: 15px;
                width: 1px;
                height: 13px;
                background-color: $color-border-1;
            }
        }
    }

    li {
        &:last-child {
            a {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.sidebar-left {
    position: absolute;
    top: 0px;
    background-color: $background-white;
    left: 0px;
    width: 50px;
    height: 100%;
    border-right: 1px solid $color-border-1;
    z-index: 999999;
    padding-top: 0px;
    padding-bottom: 25px;

    &.stick {
        .btn.btn-open {
            height: 68px;
            background: #fff url(../imgs/template/open.svg) no-repeat center center;
        }
    }

    .menu-icons {
        padding: 142px 0px 0px 0px;

        li {
            a {
                display: block;
                height: 50px;
                text-align: center;
                line-height: 50px;

                img {
                    vertical-align: middle;
                }
            }
        }
    }
}

.menu-texts {
    position: absolute;
    top: 112px;
    left: 0px;
    background-color: $background-white;
    min-width: 320px;
    border-right: 1px solid $color-border-1;
    border-bottom: 1px solid $color-border-1;

    li {
        a {
            display: block;
            padding: 0px 20px 0px 0px;
            line-height: 50px;
            font-size: 16px;
            font-weight: bold;
            color: $color-brand-3;
            text-decoration: none;

            &:hover,
            &.active {
                color: $color-black;

                .img-link {
                    position: relative;

                    &::before {
                        content: "";
                        height: 100%;
                        width: 2px;
                        background-color: $color-brand-3;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                    }
                }
            }

            img {
                vertical-align: middle;
            }

            .img-link {
                height: 50px;
                width: 50px;
                display: inline-block;
                border-right: 1px solid $color-border-1;
                text-align: center;
                margin-right: 15px;
            }
        }

        .sub-menu {
            display: none;
        }

        &:hover,
        &.submenu-open {
            .sub-menu {
                display: block;
                position: absolute;
                top: 0px;
                left: calc(100%);
                min-width: 250px;
                height: auto;
                background-color: $background-white;
                border-left: 1px solid $color-border-1;
                border-right: 1px solid $color-border-1;
                border-bottom: 1px solid $color-border-1;
                padding-top: 8px;
                padding-bottom: 10px;

                li {
                    a {
                        font-weight: 400;
                        color: $color-gray-600;
                        padding-left: 25px;
                        line-height: 38px;

                        &:hover {
                            color: $color-black;
                        }
                    }
                }
            }
        }
    }

    >li {
        &.has-children {
            >a {
                padding-right: 30px;
                background: url(../imgs/template/icons/arrow-gray.svg) no-repeat right 20px center;

                &:hover,
                &.active {
                    color: $color-black;
                    background: url(../imgs/template/icons/arrow-gray-hover.svg) no-repeat right 20px center;
                }
            }
        }
    }

    &.menu-close {
        min-width: auto;
        border: 0;

        li {
            &.has-children {
                a {
                    padding: 0px;
                    background-image: none;
                }
            }

            a {
                padding: 0px;

                .text-link {
                    display: none;
                }

                .img-link {
                    margin-right: 0px;
                }
            }
        }
    }
}

.select2-container {
    max-width: unset;
    min-width: 130px;
}