a,
button,
img,
input,
span,
h4
{
  -webkit-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
}

@keyframes slideleft{
	10%{opacity:0;transform:scale(0);right:0}
	50%{opacity:1;transform:scale(1)}
	90%{opacity:0;transform:scale(0);right:100%}
}
[data-loader='spinner']{width:35px;height:35px;display:inline-block;-webkit-animation:spinner 1.2s infinite ease-in-out;-o-animation:spinner 1.2s infinite ease-in-out;animation:spinner 1.2s infinite ease-in-out;background:url(../imgs/template/favicon.svg);box-shadow:0 0 10px #fff;}
@-webkit-keyframes spinner{
	0%{-webkit-transform:perspective(120px) rotateX(0) rotateY(0);-ms-transform:perspective(120px) rotateX(0) rotateY(0);-o-transform:perspective(120px) rotateX(0) rotateY(0);transform:perspective(120px) rotateX(0) rotateY(0);}
	50%{-webkit-transform:perspective(120px) rotateX(-180deg) rotateY(0);-ms-transform:perspective(120px) rotateX(-180deg) rotateY(0);-o-transform:perspective(120px) rotateX(-180deg) rotateY(0);transform:perspective(120px) rotateX(-180deg) rotateY(0);}
	100%{-webkit-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);-ms-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);-o-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);}
}
@-moz-keyframes spinner{
	0%{-webkit-transform:perspective(120px) rotateX(0) rotateY(0);-ms-transform:perspective(120px) rotateX(0) rotateY(0);-o-transform:perspective(120px) rotateX(0) rotateY(0);transform:perspective(120px) rotateX(0) rotateY(0);}
	50%{-webkit-transform:perspective(120px) rotateX(-180deg) rotateY(0);-ms-transform:perspective(120px) rotateX(-180deg) rotateY(0);-o-transform:perspective(120px) rotateX(-180deg) rotateY(0);transform:perspective(120px) rotateX(-180deg) rotateY(0);}
	100%{-webkit-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);-ms-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);-o-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);}
}
@-o-keyframes spinner{
	0%{-webkit-transform:perspective(120px) rotateX(0) rotateY(0);-ms-transform:perspective(120px) rotateX(0) rotateY(0);-o-transform:perspective(120px) rotateX(0) rotateY(0);transform:perspective(120px) rotateX(0) rotateY(0);}
	50%{-webkit-transform:perspective(120px) rotateX(-180deg) rotateY(0);-ms-transform:perspective(120px) rotateX(-180deg) rotateY(0);-o-transform:perspective(120px) rotateX(-180deg) rotateY(0);transform:perspective(120px) rotateX(-180deg) rotateY(0);}
	100%{-webkit-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);-ms-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);-o-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);}
}
@keyframes spinner{
	0%{-webkit-transform:perspective(120px) rotateX(0) rotateY(0);-ms-transform:perspective(120px) rotateX(0) rotateY(0);-o-transform:perspective(120px) rotateX(0) rotateY(0);transform:perspective(120px) rotateX(0) rotateY(0);}
	50%{-webkit-transform:perspective(120px) rotateX(-180deg) rotateY(0);-ms-transform:perspective(120px) rotateX(-180deg) rotateY(0);-o-transform:perspective(120px) rotateX(-180deg) rotateY(0);transform:perspective(120px) rotateX(-180deg) rotateY(0);}
	100%{-webkit-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);-ms-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);-o-transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);transform:perspective(120px) rotateX(-180deg) rotateY(-180deg);}
}
@keyframes shadow-pulse{
	0%{box-shadow:0 0 0 0px rgba(151,119,250,0.8);}
	100%{box-shadow:0 0 0 5px rgba(0,0,0,0);}
}
@keyframes shadow-pulse-big{
	0%{box-shadow:0 0 0 0px rgba(239,63,72,0.1);}
	100%{box-shadow:0 0 0 20px rgba(0,0,0,0);}
}
@keyframes jump{
	0%{transform:translate3d(0,20%,0);}
	100%{transform:translate3d(0,0,0);}
}
.jump{transform-origin:0;animation:jump .5s linear alternate infinite;}

@-webkit-keyframes wiggle {
	0%,
	100% {
	  transform: scale(1.2) rotate(7deg);
	}

	50% {
	  transform: scale(0.8) rotate(-7deg);
	}
  }

  @keyframes wiggle {
	0%,
	100% {
	  transform: scale(1.2) rotate(7deg);
	}

	50% {
	  transform: scale(0.8) rotate(-7deg);
	}
  }

  /*animation*/
@-webkit-keyframes slide-down {
	from {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}

	to {
		-webkit-transform: translateY(0%);
		transform: translateY(0%);
	}

}

@keyframes slide-down {
	from {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}

	to {
		-webkit-transform: translateY(0%);
		transform: translateY(0%);
	}

}

@-webkit-keyframes slide-up {
	from {
		-webkit-transform: translateY(0%);
		transform: translateY(0%);
	}

	to {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}

}

@keyframes slide-up {
	from {
		-webkit-transform: translateY(0%);
		transform: translateY(0%);
	}

	to {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}

}

.rotateme {
	animation-name: rotateme;
	animation-duration: 40s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes rotateme {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}

}

@-webkit-keyframes rotateme {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}

}

@-moz-keyframes rotateme {
	from {
		-moz-transform: rotate(0deg);
	}

	to {
		-moz-transform: rotate(360deg);
	}

}

@-o-keyframes rotateme {
	from {
		-o-transform: rotate(0deg);
	}

	to {
		-o-transform: rotate(360deg);
	}

}

@-webkit-keyframes rotate3d {
	0% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}

	100% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
	}

}

@keyframes rotate3d {
	0% {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}

	100% {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
	}

}

@keyframes animationFramesOne {
	0% {
		transform: translate(0px, 0px)  rotate(0deg);
	}

	20% {
		transform: translate(73px, -1px)  rotate(36deg);
	}

	40% {
		transform: translate(141px, 72px)  rotate(72deg);
	}

	60% {
		transform: translate(83px, 122px)  rotate(108deg);
	}

	80% {
		transform: translate(-40px, 72px)  rotate(144deg);
	}

	100% {
		transform: translate(0px, 0px)  rotate(0deg);
	}

}

@-webkit-keyframes animationFramesOne {
	0% {
		-webkit-transform: translate(0px, 0px)  rotate(0deg);
	}

	20% {
		-webkit-transform: translate(73px, -1px)  rotate(36deg);
	}

	40% {
		-webkit-transform: translate(141px, 72px)  rotate(72deg);
	}

	60% {
		-webkit-transform: translate(83px, 122px)  rotate(108deg);
	}

	80% {
		-webkit-transform: translate(-40px, 72px)  rotate(144deg);
	}

	100% {
		-webkit-transform: translate(0px, 0px)  rotate(0deg);
	}

}

@-webkit-keyframes ripple-white {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2)
	}

	100% {
		-webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0)
	}

}

@keyframes ripple-white {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2)
	}

	100% {
		-webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0)
	}

}

/* End Shape rotate */
@keyframes moveBounce {
	0% {
		transform: translateY(0px);
	}

	50% {
		transform: translateY(20px);
	}

	100% {
		transform: translateY(0px);
	}

}

@keyframes moveLeftBounce {
	0% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(5px);
	}

	100% {
		transform: translateX(0);
	}

}

@keyframes ripple {
	0% {
		transform: scale(1)
	}

	75% {
		transform: scale(1.75);
		opacity: 1
	}

	100% {
		transform: scale(2);
		opacity: 0
	}

}

@keyframes rotateMe {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}

}

@keyframes moveScale {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(.8);
	}

	100% {
		transform: scale(1);
	}

}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1.0);
	}

}

@keyframes animationFramesOne {
	0% {
		transform: translate(0px, 0px) rotate(0deg);
	}

	20% {
		transform: translate(73px, -1px) rotate(36deg);
	}

	40% {
		transform: translate(141px, 72px) rotate(72deg);
	}

	60% {
		transform: translate(83px, 122px) rotate(108deg);
	}

	80% {
		transform: translate(-40px, 72px) rotate(144deg);
	}

	100% {
		transform: translate(0px, 0px) rotate(0deg);
	}

}