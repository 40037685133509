// New Agon
.banner-hero {
    padding: 24px 0px 20px 0px;
    position: relative;
}
@keyframes hero-thumb-animation {
    0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes hero-thumb-animation-2 {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-20px);
    }
}
@keyframes hero-thumb-sm-animation {
    0% {
        -webkit-transform: translateY(-20px) translateX(50px);
        -moz-transform: translateY(-20px) translateX(50px);
        -ms-transform: translateY(-20px) translateX(50px);
        transform: translateY(-20px) translateX(50px);
    }
    100% {
        -webkit-transform: translateY(-20px) translateX(0px);
        -moz-transform: translateY(-20px) translateX(0px);
        -ms-transform: translateY(-20px) translateX(0px);
        transform: translateY(-20px) translateX(0px);
    }
}
@keyframes hero-thumb-sm-2-animation {
    0% {
        -webkit-transform: translateY(-50px);
        -moz-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        transform: translateY(-50px);
    }
    100% {
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
    }
}
.shape-1 {
    -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
    -moz-animation: hero-thumb-animation 2s linear infinite alternate;
    -o-animation: hero-thumb-animation 2s linear infinite alternate;
    animation: hero-thumb-animation 2s linear infinite alternate;
}
.shape-1-2 {
    -webkit-animation: hero-thumb-animation-2 2s linear infinite alternate;
    -moz-animation: hero-thumb-animation-2 2s linear infinite alternate;
    -o-animation: hero-thumb-animation-2 2s linear infinite alternate;
    animation: hero-thumb-animation-2 2s linear infinite alternate;
}
.shape-2 {
    -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
    animation: hero-thumb-sm-animation 4s linear infinite alternate;
}
.shape-3 {
    -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
    animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}
// End New Agon