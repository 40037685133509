.list-tags-col-5 {
    display: flex;
    margin: 0px -10px;
    flex-wrap: wrap;
    justify-content: center;
    li {
        flex: 0 0 auto;
        width: 20%;
        padding: 0px 10px;
        min-width: 150px;
        max-width: 180px;
    }
}
.box-topics {
    &.box-topics-2 {
        padding: 0px 50px;
        position: relative;
        border: 0px;
        background-color: transparent;
    }
}
.banner-home2 {
    padding-top: 80px;
}