.icon-media {
    display: inline-block;
    padding: 8px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 17.5px;
    margin: 0px 10px;
    width: 20px;
    &.icon-fb {
        background-image: url(../imgs/page/single/fb.svg);
    }
    &.icon-tw {
        background-image: url(../imgs/page/single/tw.svg);
    }
    &.icon-printest {
        background-image: url(../imgs/page/single/pi.svg);
    }
}
.box-share {
    display: flex;
    width: 100%;
    align-items: center;
    height: 80px;
    border-left-width: 1px;
    border-left-style: solid;
    padding-left: 40px;
    text-align: right;
}
.box-quote {
    padding: 120px 100px 70px 100px;
    background-image: url(../imgs/page/single/left-quote.svg);
    background-repeat: no-repeat;
    background-position: top 50px center;
    border-radius: 16px;
    text-align: center;
    margin: 50px 0px;
}
.content-detail {
    padding-bottom: 50px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 30px;
}
@media (min-width: 1200px) {
    .gallery-left {
        width: 47%;
    }
    .gallery-right {
        width: 53%;
    }
}
.box-tags {
    padding: 20px 0px;
    .btn {
        margin-bottom: 10px;
    }
}
.box-comments {
    margin-top: 30px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-bottom: 40px;
    margin-bottom: 40px;
}
.list-comments-single {
    padding: 40px 0px 0px 0px;
    .item-comment {
        display: flex;
        width: 100%;
        margin-bottom: 40px;
    }
}

.comment-left {
    max-width: 270px;
    width: 100%;
    padding-right: 20px;
}
.item-comment-sub {
    padding-left: 40px;
    .comment-left {
        max-width: 230px;
    }
}
.comment-right {
    width: 100%;
}
.text-comment {
    border-width: 1px;
    border-style: solid;
    padding: 20px;
    border-radius: 16px;
}
.cb-agree {
    float: left;
    margin: 4px 5px 0px 0px;
}