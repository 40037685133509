/*TYPOGRAPHY*/
body {
    color: $color-gray-500;
    background-color: $color-gray-900;
    font-family: $font-text;
    font-size: 14px;
    line-height: 18px;
    font-style: normal;
    font-weight: 400;
    position: relative;
}
.theme-day {
    body {
        background-color: $color-gray-900-day;
    }
}
.font-heading,
.display-1,
.display-2,
.heading-lg,
.heading-sm-1 {
    font-family: $font-text;
    color: $color-brand-3;
    font-weight: 700;
    line-height: 1.2;
}
.page-content {
    line-height: 1.25;
    p   {
        margin-bottom: 10px;
    }
    h5 {
        margin-bottom: 15px;
    }
}
a.text-brand-3 {
    color: $color-brand-3;
    &:hover {
        color: $color-black;
    }
}
.font-xs {
    font-size: 12px;
}