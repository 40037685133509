.header {
    padding: 10px 0px;
    margin-top: 15px;
    float: left;
    width: 100%;
    position: relative;
    .main-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        padding: 5px 0px;
        .header-logo {
            max-width: 150px;
            width: 100%;
            a {
                display: block;
                img {
                    min-height: 37px;
                }
            }
        }
        .header-nav {
            display: inline-block;
            width: 65%;
            text-align: center;
        }
        .header-search {
            width: 35%;
            .box-header-search {
                border-radius: 8px;
                border: 1px solid $color-border-1;
                padding: 5px;
                .form-search {
                    display: flex;
                }
                .box-keysearch {
                    width: 100%;
                    input {
                        border: 0px !important;
                    }
                }
                .box-category {
                    position: relative;
                    margin-right: 15px;
                    min-width: 120px;
                    &::before {
                        content: "";
                        height: 14px;
                        width: 1px;
                        background-color: #EBF0F3;
                        position: absolute;
                        top: 8px;
                        right: -14px;
                    }
                    select {
                        height: 100%;
                        width: 100%;
                        border: 0px;
                        padding: 0px 20px 0px 5px;
                        border-radius: 8px;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        background-image: url(../imgs/template/arrow-down.svg);
                        background-position: right center;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
        .header-shop {
            width: 35%;
            text-align: right;
            .icon-list {
                display: inline-block;
                padding: 0px 15px 0px 30px;
                background-repeat: no-repeat;
                background-position: 0px -1px;
                position: relative;
                font-size: 16px;
                color: $color-brand-3;
                line-height: 24px;
                cursor: pointer;
                &:hover {
                    color: $color-black;
                }
                &:last-child {
                    padding-right: 0px;
                }
                img {
                    float: left;
                    margin: 3px 6px 0px 0px;
                }
            }
            .icon-account {
                background-image: url(../imgs/template/account.svg);
            }
            .icon-wishlist {
                background-image: url(../imgs/template/wishlist.svg);
                background-position: 0px 2px;
                padding-left: 35px;
            }
            .icon-cart {
                background-image: url(../imgs/template/cart.svg);
                background-position: 0px 2px;
                padding-left: 35px;
            }
            .icon-compare {
                background-image: url(../imgs/template/compare.svg);
                background-position: 0px 2px;
            }
            .number-item {
                background-color: $color-brand-2;
                border-radius: 50%;
                position: absolute;
                top: -8px;
                left: 14px;
                width: 20px;
                height: 20px;
                color: $color-white;
                text-align: center;
                line-height: 20px;
                display: inline-block;
            }
        }
        .header-right {
            max-width: 225px;
            width: 100%;
            .block-signin {
                text-align: right;
            }
        }
    }
    .nav-main-menu {
        width: auto;
        padding: 0px;
        float: none;
    }
    .burger-icon {
        display: none;
    }
    .main-menu {
        display: inline-block;
        padding-top: 3px;
        li {
            float: left;
            position: relative;
            text-align: left;
            &.has-children {
                >a {
                    &::after {
                        content: "\f151";
                        font-family: uicons-regular-rounded !important;
                        font-style: normal;
                        font-weight: normal !important;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1 !important;
                        font-size: 14px;
                        opacity: 1;
                        margin-left: 0px;
                        position: relative;
                        top: 2px;
                    }
                }
            }
            &.hr {
                padding: 0px 22px;
                span {
                    background-color: $color-gray-100;
                    height: 1px;
                    width: 100%;
                    display: block;
                    margin: 5px 0;
                }
            }
            a {
                font-family: $font-text;
                font-style: normal;
                font-weight: 400;
                font-size: $font-md;
                line-height: 16px;
                display: block;
                padding: 13px 17px;
                text-decoration: none;
                position: relative;
                &:hover, &.active {
                    color: $color-brand-2;
                    i {
                        opacity: 1;
                        transition: 0.2s;
                    }
                }
                i {
                    font-size: 12px;
                    margin-right: 8px;
                    opacity: 0.38;
                    transition: 0.2s;
                }
            }
            ul {
                opacity: 0;
                visibility: hidden;
                -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
                position: absolute;
                top: calc(100% + 9px);
                left: 0px;
                z-index: 999999;
                min-width: 180px;
                border-radius: 0 0 4px 4px;
                padding: 8px 0px 8px 0;
                background: $color-gray-850;
                /* Gray/800 [night] */

                border: 1px solid $color-gray-800;
                box-shadow: 0px 23.1405px 69.4215px rgba(0, 0, 0, 0.12);
                border-radius: 8px;
                li {
                    width: 100%;
                    a {
                        font-size: 14px;
                        color: $color-gray-500;
                        font-size: $font-xs;
                        line-height: 16px;
                        position: relative;
                        padding: 10px 22px 10px 38px;
                        background: url(../imgs/template/icons/icon-menu.svg) no-repeat left 20px center;
                        transition: 0.2s;
                        &:hover {
                            transition: 0.2s;
                            background: url(../imgs/template/icons/icon-menu-hover.svg) no-repeat left 20px center;
                        }
                        &.closer {
                            padding: 8px 22px 8px 22px;
                        }
                    }
                    ul {
                        top: 0px;
                        left: 100%;
                    }
                }
            }
            &:hover>ul {
                opacity: 1;
                visibility: visible;
                li {
                    width: 100%;
                }
            }
        }
    }
    .text-link-bd-btom {
        font-family: $font-text;
        font-style: normal;
        font-weight: 500;
        font-size: $font-md;
        line-height: 150%;
        color: $color-gray-900;
        display: inline-block;
        position: relative;
        padding-left: 13px;
        &:hover {
            color: $color-gray-900;
        }
        &::before {
            content: "";
            background: $color-gray-900;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -3px;
            animation: shadow-pulse 1s infinite;
        }
    }
    .block-signin {
        .btn-shadow {
            box-shadow: $box-shadow-3;
        }
    }
    &.stick {
        padding: 10px 0px;
        margin-top: 0;
    }
}
.switch-button {
    display: inline-block;
    padding: 0px 15px 0px 5px;
    .form-switch {
        padding-left: 0px;
        display: inline-block;
        vertical-align: middle;
    }
    .form-switch .form-check-input {
        width: 40px;
        height: 22px;
        margin-left: 0px;
        background-image: url(../imgs/template/icons/mode.svg);
        background-position: left 2px center;
        background-color: $color-gray-600;
        border-color: $color-gray-600;
        background-size: 18px;
    }
    .form-check-input:checked {
        background-color: $color-gray-800;
        border-color: $color-gray-800;
    }
    .form-switch .form-check-input:checked {
        background-position: right 2px center;
        background-image: url(../imgs/template/icons/mode.svg);
    }
}
.sticky-bar {
    &.stick {
        -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999999;
        left: 0;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        border-bottom: 1px solid $color-gray-800;
    }
}

.user-account {
    display: flex;
    align-items: center;
    img {
        max-width: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .user-name {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 14px;
    }
}

.perfect-scroll {
    height: 100vh;
    width: 100%;
    position: relative;
}

.body-overlay-1 {
    background: transparent;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    cursor: crosshair;
    z-index: 1002;
}

.mobile-menu-active .body-overlay-1 {
    opacity: 1;
    visibility: visible;
}

// homepage2
.header-top {
    background-color: $color-gray-900;
    color: $color-white;
    padding: 15px 0px;
    margin-top: 30px;
    span {
        font-family: $font-heading;
        font-size: $font-md;
        line-height: 24px;
        font-weight: bold;
    }
    a {
        color: $color-gray-900;
    }
    .line-right {
        position: relative;
        display: inline-block;
        padding-right: 35px;
        &::before {
            content: "";
            position: absolute;
            top: 5px;
            right: 15px;
            width: 1px;
            height: 18px;
            border-right: 1px dashed $color-gray-900;
        }
    }
    &.header-top-green {
        background-color: $color-gray-900;
        a {
            color: $color-gray-100;
        }
        .line-right {
            &::before {
                border-right: 1px dashed $color-gray-100;
            }
        }
    }
}


@media (min-width: 1400px) {
    .header-container {
        .container {
            max-width: 1360px;
        }
    }
}

.header-container {
    .main-header .header-left .header-nav {
        width: 35%;
    }
    .main-header .header-left .header-shop {
        width: 45%;
    }
}

.header-bottom {
    border-top: 1px solid $color-border-1;
    padding: 10px 0px 10px 0px;
    .dropdown-toggle::after {
        display: none;
    }
    .container {
        display: flex;
    }
    .dropdown {
        padding: 0px;
    }
    .header-nav {
        width: 100%;
        padding-left: 20px;
        .main-menu {
            padding-top: 0px;
            li {
                a {
                    padding: 9px 15px 5px 15px;
                }
            }
            li.has-children>a::after {
                content: "";
                background: url(../imgs/page/homepage4/angle-down.svg) no-repeat 0px 0px;
                height: 10px;
                width: 11px;
                position: absolute;
                top: 50%;
                right: 0px;
                margin-top: -4px;
            }
        }
    }
    .discount {
        display: inline-block;
        padding: 9px 0px 9px 42px;
        background-image: url(../imgs/page/homepage4/discount.svg);
        background-position: left center;
        background-repeat: no-repeat;
        min-width: 160px;
        max-height: 53px;
    }
    .dropdown-right {
        background-image: url(../imgs/page/homepage4/arrow-down.svg);
        background-position: right center;
        background-repeat: no-repeat;
        padding: 0px 20px 0px 0px;
        display: block;
        margin-left: 0px;
    }
    .dropdown-menu {
        width: 100%;
        padding: 3px 0px;
        .dropdown-item
        {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}
.dropdown-menu {
    border: 1px solid $color-border-1;
    border-radius: 4px;
}
.menu-topbar-right {
    .dropdown-menu {
        border: 1px solid $color-border-1;
        border-radius: 0 0 4px 4px;
    }
    .dropdown-menu[data-bs-popper] {
        margin-top: 10px;
    }
}


.dropdown-item {
    font-size: 14px;
    color: $color-brand-3;
    &.active, &:active, &:hover {
        color: $color-brand-3;
        text-decoration: none;
        background-color: $color-border-1;
    }

}
.dropdown-language {
    .dropdown-item {
        img {
            margin-right: 5px;
        }
    }

}

.box-dropdown-cart {
    position: relative;
}
.dropdown-cart {
    display: none;
    visibility: hidden;
    position: absolute;
    right: -70px;
    top: calc(100% + 22px);
    z-index: 99;
    width: 320px;
    background-color: #fff;
    padding: 22px;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 0 0 4px 4px;
    border: 1px solid $color-border-1;
    &.dropdown-open {
        display: block;
        visibility: visible;
    }
}
.dropdown-account {
    width: 200px;
    left: 0;
    display: none;
    visibility: hidden;
    position: absolute;
    top: calc(100% + 22px);
    z-index: 99;
    width: 220px;
    background-color: #fff;
    padding: 22px;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 0 0 4px 4px;
    border: 1px solid $color-border-1;
    &.dropdown-open {
        display: block;
        visibility: visible;
    }
    ul {
        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin: 0 0 20px;
            &:last-child {
                margin: 0;
            }
            a {
                color: $color-brand-3;
                &:hover {
                    color: $color-black;
                }
            }
        }
    }
}
.box-breadcrumbs {
    display: inline-block;
    .breadcrumb {
        margin-bottom: 0px;
        li {
            position: relative;
            padding: 0px 30px 0px 0px;
            &::before {
                content: "";
                position: absolute;
                top: 9px;
                right: 10px;
                height: 15px;
                width: 15px;
                background: url(../imgs/template/icons/right-arrow.svg) no-repeat top right;
            }
            &:last-child {
                padding-right: 0px;
                &::before {
                    display: none;
                }
            }
            a {
                display: inline-block;
                color: $color-gray;
                font-size: 14px;
                line-height: 19px;
                padding-top: 5px;
                padding-bottom: 5px;
                &:hover {
                    color: $color-brand-1;
                }
                &.home {
                    background: url(../imgs/template/icons/home.svg) no-repeat left center;
                    padding-left: 30px;
                }
            }
            span {
                display: inline-block;
                padding: 5px 0;
            }
        }
    }
}
.form-search {
    position: absolute;
    top: 100%;
    right: 98px;
    width: 100%;
    max-width: 350px;
    z-index: 1234;
    background-color: $color-gray-950;
    padding: 5px 5px;
    display: none;
    border-radius: 8px;
    border: 1px solid $color-gray-800;
    .form-control {
        height: 46px;
        background-color: $color-gray-900;
        border-color: $color-gray-800;
        font-size: 14px;
        line-height: 1;
        padding: 10px 15px 10px 20px;
    }
    .btn-search-2 {
        position: absolute;
        top: 22px;
        right: 28px;
        width: 40px;
        height: 40px;
        background: url(../imgs/template/icons/icon-search.svg) no-repeat center;
        border: 0px;
    }
}
.popular-keywords {
    a:hover {
        color: $color-white;
    }
}